import { useEffect, useState } from "react";
import { auth } from "../firebase";
import { Flex, Spinner, Text } from "@chakra-ui/react";

export default function SplashScreenLayout({ children }) {
  const [authLoaded, setAuthLoaded] = useState(false);
  const [delayLoaded, setDelayLoaded] = useState(false);
  const [splashScreenEnabled, setSplashScreenEnabled] = useState(true);
  auth.onAuthStateChanged(() => {
    setAuthLoaded(true);
  });

  useEffect(() => {
    setTimeout(() => {
      setDelayLoaded(true);
    }, 2000);
  }, []);

  const loaded = authLoaded && delayLoaded;

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setSplashScreenEnabled(false);
      }, 1000);
    }
  }, [loaded]);

  return (
    <>
      {splashScreenEnabled && (
        <Flex
          className={loaded ? "animate__animated animate__fadeOutLeft" : ""}
          position={"fixed"}
          top={0}
          left={0}
          flexDirection={"column"}
          width={"full"}
          height={"100%"}
          backgroundSize={"cover"}
          backgroundPosition={"center"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text fontSize={"4xl"} className="logo">
            My Footsteps
          </Text>
          <Spinner color="blue.500" marginTop={4} />
        </Flex>
      )}
      {!splashScreenEnabled && children}
    </>
  );
}
