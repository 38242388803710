import { Route, Routes } from "react-router-dom";
import IndexPage from "../pages/index.page";
import MustLoginLayout from "../layouts/must-login.layout";
import LoginPage from "../pages/auth/login.page";

export default function GeneralRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MustLoginLayout />}></Route>
      <Route index element={<IndexPage />} />
      <Route path="auth">
        <Route path="login" element={<LoginPage />} />
      </Route>
    </Routes>
  );
}
