import {
  Button,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import FontAwesomeIcon from "../font-awesome-icon";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useMemo } from "react";
import { ATTRACTION, HOTEL, MUSEUM, RESTAURANT, STADIUM, TEMPLE } from "./type";
import _ from "lodash";

export default function MapPin({
  position,
  location,
  type,
  color = "red.500",
  ...props
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const icon = useMemo(() => {
    const type = _.get(location, "type", "unknown");
    switch (type) {
      case RESTAURANT:
        return "fork-knife";
      case HOTEL:
        return "bed";
      case ATTRACTION:
        return "mountains";
      case TEMPLE:
        return "gopuram";
      case MUSEUM:
        return "building-columns";
      case STADIUM:
        return "person-running";
      default:
        return "fa-location-pin";
    }
  }, [location]);

  return (
    <>
      <AdvancedMarker
        onClick={onOpen}
        position={{ lat: location.latitude, lng: location.longitude }}
      >
        <Circle
          backgroundColor={color}
          fontSize={"sm"}
          size={8}
          color={"white"}
          borderWidth={2}
          borderColor={"white"}
          boxShadow={"md"}
          {...props}
        >
          <FontAwesomeIcon name={icon} />
        </Circle>
      </AdvancedMarker>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader textAlign={"center"}>
            {_.get(location, "displayName", "未命名地點")}
          </DrawerHeader>

          <DrawerBody></DrawerBody>

          <DrawerFooter>
            <Button
              size={"sm"}
              variant={"ghost"}
              colorScheme="blue"
              onClick={onClose}
            >
              關閉
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
