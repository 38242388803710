import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Circle, Flex, useBreakpointValue } from "@chakra-ui/react";
import _ from "lodash";
import MapPin from "../components/map-pin";
import BottomBar from "../components/bottombar";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";

export default function MapPage() {
  const mapRef = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [currentPositionLoaded, setCurrentPositionLoaded] = useState(false);
  useEffect(() => {
    if ("geolocation" in navigator) {
      // navigator.geolocation.getCurrentPosition((position) => {
      //   console.log(position);
      // });
      const watchId = navigator.geolocation.watchPosition((position) => {
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setCurrentPositionLoaded(true);
      });
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    } else {
      setCurrentPosition({
        lat: 24.16335363131227,
        lng: 120.67708971607712,
      });
      setCurrentPositionLoaded(true);
    }
  }, []);

  const onMapClicked = useCallback(async (event) => {
    const placeId = _.get(event, "detail.placeId", null);
    console.log(event.detail);
    // click on the place
    if (placeId) {
      console.log(placeId);
    }
  }, []);

  const mode = useBreakpointValue({
    base: "fixed",
    md: "float",
  });

  const [locations, setLocations] = useState([]);

  const loadLocations = useCallback(async () => {
    const locationQuerySnapshot = await getDocs(
      collection(firestore, "locations"),
    );
    const locations = locationQuerySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    setLocations(locations);
  }, []);

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  return (
    <>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API}>
        <Box paddingBottom={mode === "fixed" ? 16 : 0}>
          <Flex>
            {currentPositionLoaded && (
              <Map
                maxZoom={20}
                minZoom={6}
                onClick={onMapClicked}
                mapId={"6398707315995076"}
                style={{
                  width: "100%",
                  height: mode === "fixed" ? "calc(100vh - 4rem)" : "100vh",
                }}
                defaultCenter={currentPosition}
                defaultZoom={14}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                onLoad={(map) => (mapRef.current = map)}
              >
                {locations.map((location) => {
                  return <MapPin location={location} key={location.id} />;
                })}

                {currentPosition && (
                  <AdvancedMarker position={currentPosition}>
                    <Circle
                      backgroundColor={"red.600"}
                      fontSize={"sm"}
                      size={4}
                      color={"white"}
                      borderWidth={2}
                      borderColor={"white"}
                      boxShadow={"md"}
                      transition={"all 1s !important"}
                    ></Circle>
                  </AdvancedMarker>
                )}
              </Map>
            )}
          </Flex>
        </Box>
        <BottomBar currentPosition={currentPosition}></BottomBar>
      </APIProvider>
    </>
  );
}
