import {
  Box,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Square,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import FontAwesomeIcon from "../font-awesome-icon";
import { useMap } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import _ from "lodash";
import { ulid } from "ulid";

export default function BottomBar({ currentPosition = null }) {
  const map = useMap();

  const moveToCurrentPosition = useCallback(
    async (event) => {
      if (currentPosition) {
        map.panTo(currentPosition);
      }
    },
    [map, currentPosition],
  );

  const mode = useBreakpointValue({
    base: "fixed",
    md: "float",
  });

  const [folders, setFolders] = useState([]);

  const loadFolders = useCallback(async () => {
    const folderQuerySnapshot = await getDocs(collection(firestore, "folders"));
    const folders = folderQuerySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    setFolders(folders);
  }, []);

  useEffect(() => {
    loadFolders();
  }, [loadFolders]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        left={mode === "fixed" ? 0 : "50%"}
        width={mode === "fixed" ? "full" : "auto"}
        transform={mode === "fixed" ? "none" : "translateX(-50%)"}
        justifyContent={"center"}
        position={"fixed"}
        bottom={mode === "fixed" ? 0 : 12}
        height={16}
      >
        <Flex
          backgroundColor={"white"}
          columns={2}
          width={mode === "fixed" ? "full" : "fit-content"}
          bottom={mode === "fixed" ? 0 : 12}
          left={0}
          height={16}
          borderTopWidth={"1px"}
          borderTopColor={"gray.200"}
          borderRadius={mode === "fixed" ? "unset" : "md"}
          boxShadow={mode === "fixed" ? "none" : "md"}
        >
          <Flex
            onClick={moveToCurrentPosition}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            color={"gray.500"}
            cursor={"pointer"}
            minWidth={24}
            userSelect={"none"}
            width={"full"}
          >
            <Text fontSize={"xl"}>
              <FontAwesomeIcon name={"house"} />
            </Text>
            <Text fontSize={"xs"}>當前位置</Text>
          </Flex>
          <Flex
            onClick={onOpen}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            color={"gray.800"}
            cursor={"pointer"}
            minWidth={24}
            userSelect={"none"}
            width={"full"}
          >
            <Text fontSize={"xl"}>
              <FontAwesomeIcon name={"list"} />
            </Text>
            <Text fontSize={"xs"}>資料夾</Text>
          </Flex>
        </Flex>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <Text textAlign={"center"} className="logo">
                My Footstep
              </Text>
            </DrawerHeader>
            <DrawerBody>
              <Box paddingTop={12}>
                {folders.map((folder) => {
                  return (
                    <Flex
                      alignItems={"center"}
                      columnGap={2}
                      key={_.get(folder, "id", ulid())}
                    >
                      <Square size={12} fontSize={"2xl"}>
                        <FontAwesomeIcon name={"folder"} />
                      </Square>
                      <Text fontWeight={"bold"}>
                        {_.get(folder, "title", "未命名資料夾")}
                      </Text>
                    </Flex>
                  );
                })}
              </Box>
            </DrawerBody>

            <DrawerFooter>
              <Circle backgroundColor={"gray.200"} size={12} onClick={onClose}>
                <FontAwesomeIcon name={"times"}></FontAwesomeIcon>
              </Circle>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
}
